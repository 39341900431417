<template>
    <el-dialog
            v-bind="$attrs" v-on="$listeners"
            width="40%"
            :append-to-body="true"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            title="任务详情"
    >

        <el-descriptions title="" :column="2">
            <el-descriptions-item label="任务名称">{{ajaxParam.jobName|g_filter_empty}}</el-descriptions-item>
            <el-descriptions-item label="任务组名">{{ajaxParam.groupCode|g_filter_empty}}</el-descriptions-item>

            <el-descriptions-item label="cron表达式">{{ajaxParam.cronDesc|g_filter_empty}}</el-descriptions-item>
            <el-descriptions-item label="code码">{{ajaxParam.triggerCode|g_filter_empty}}</el-descriptions-item>

            <el-descriptions-item label="调用类名">{{ajaxParam.executeC|g_filter_empty}}</el-descriptions-item>
            <el-descriptions-item label="下次执行时间">{{ajaxParam.nextTime|g_filter_empty}}</el-descriptions-item>

            <el-descriptions-item label="调用方法">{{ajaxParam.executeMethod|g_filter_empty}}</el-descriptions-item>
            <el-descriptions-item label="任务状态" >{{ajaxParam.jobStatusDesc|g_filter_empty}}</el-descriptions-item>
        </el-descriptions>

    </el-dialog>
</template>

<script>
export default {
    name: "LookDetails",
    props:{
        recordRow:{
            type: Object,
            default:()=>null
        }
    },
    data(){
        return{
            ajaxParam:{
                key:null,
                cronDesc:"",// cron表达式
                executeC:"",//  (query)	 调用类
                executeCode:1,// 执行策略：1.立即执行，2.执行一次
                executeMethod: "",//调用方法
                groupCode: "",//组名称(size-20)
                jobCode:"",// 任务编码(size-20)
                jobName:"",// 任务名称(size-20)
                "executeStrategy": "",
                "jobStatus": "",
                "jobStatusDesc": "",
                "nextTime": "",
                "schedulerName": "",
                "triggerCode": ""
            }
        }
    },
    created() {
        this.lookfor()
    },
    methods:{
        async lookfor(){
            let {code,data} = await  this.$api.get("/quartz/server/escort/web/quartzManage/detail",{
                params:{
                    groupCode:this.recordRow.groupCode,
                    jobCode:this.recordRow.jobCode,
                }
            });
            if(code===200){
                Object.keys(this.ajaxParam).forEach(item=>{
                    this.ajaxParam[item] = data[item]
                })
            }
        }
    }
}
</script>

<style scoped>
.el-descriptions{
    box-sizing: border-box;
    margin: 0 30px;
}
</style>