<template>
    <el-dialog center
            v-bind="$attrs" v-on="$listeners"
            width="40%"
            :append-to-body="true"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :title="`${recordRow?'编辑':'添加'}`+'任务'"
    >
        <el-form
                ref="ruleForm"
                label-width="110px"
                class="allForm"
                :rules="rules"
                :model="ajaxParam"
        >
            <el-form-item label="任务名称" prop="jobName">
                <el-input v-model.trim="ajaxParam.jobName"  placeholder="请输入任务名称"/>
            </el-form-item>

            <el-form-item label="code码" prop="jobCode">
                <el-input v-model.trim="ajaxParam.jobCode"    placeholder="请输入（仅字母）"/>
            </el-form-item>

            <el-form-item label="任务组名" prop="groupCode">
                <el-input v-model.trim="ajaxParam.groupCode"    placeholder="请输入（仅字母）"/>
            </el-form-item>

            <el-form-item label="调用类名" prop="executeC">
                <el-input v-model.trim="ajaxParam.executeC"    placeholder="请输入调用目标字符串"/>
            </el-form-item>

            <el-form-item label="调用方法" prop="executeMethod">
                <el-input v-model.trim="ajaxParam.executeMethod"    placeholder="请输入调用目标字符串"/>
            </el-form-item>

            <el-form-item label="cron表达式" prop="cronDesc">
                <el-input v-model.trim="ajaxParam.cronDesc"    placeholder="请输入cron执行表达式">
                    <el-button class="el-button-cron" slot="suffix" type="primary" icon="el-icon-circle-plus-outline" @click="openCron=true">生成表达式</el-button>
                </el-input>
            </el-form-item>

<!--            <el-form-item label="执行策略">-->
<!--                <el-radio-group v-model="ajaxParam.executeCode"  size="small">-->
<!--                    <el-radio-button :label="1">立即执行</el-radio-button>-->
<!--                    <el-radio-button :label="2">执行一次</el-radio-button>-->
<!--                </el-radio-group>-->
<!--            </el-form-item>-->



        </el-form>

        <div slot="footer" class="dialog-footer" style="text-align: center;">
            <el-button type="primary" @click="confirm">确定</el-button>
            <el-button type="info" @click="$emit('close')">取消</el-button>
        </div>

        <el-dialog title="Cron表达式生成器" :visible.sync="openCron" append-to-body destroy-on-close class="scrollbar"  :close-on-click-modal="false" >
            <Crontab  @hide="openCron=false" @fill="crontabFill" :expression="ajaxParam.expression"></Crontab >
        </el-dialog>
    </el-dialog>
</template>

<script>
import Crontab from './Crontab/index.vue'
import validates from "./validates.js";
export default {
    name:"AddOrEdit",
    components:{Crontab},
    computed: {
        rules() {
            return validates;
        },
    },
    props:{
        recordRow:{
            type: Object,
            default:()=>null
        }
    },
    data(){
        return{
            ajaxParam:{
                cronDesc:"",// cron表达式
                executeC:"",//  (query)	 调用类
                // executeCode:1,// 执行策略：1.立即执行，2.执行一次
                executeMethod: "",//调用方法
                groupCode: "",//组名称(size-20)
                jobCode:"",// 任务编码(size-20)
                jobName:"",// 任务名称(size-20)
            },

            // 是否显示Cron表达式弹出层
            openCron: false,


        }
    },
    created() {
        this.recordRow&&this.lookfor();
    },
    methods:{
        async lookfor(){
            let {code,data} = await  this.$api.get("/quartz/server/escort/web/quartzManage/detail",{
                params:{
                    groupCode:this.recordRow.groupCode,
                    jobCode:this.recordRow.jobCode,
                }
            });
            if(code===200){
                Object.keys(this.ajaxParam).forEach(item=>{
                    this.ajaxParam[item] = data[item]
                })
            }
        },

        confirm() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.recordRow ? this.edit(): this.add()
                }
            });
        },

        async add(){
            let {code} = await  this.$api.post("/quartz/server/escort/web/quartzManage",null,{
                params:this.ajaxParam
            });
            if(code === 200){
                this.$message({
                    message: '添加成功',
                    type: 'success',
                    center: true
                });
                this.$emit("close");
            }
        },

        async edit(){
            let {code} = await  this.$api.put("/quartz/server/escort/web/quartzManage",null,{
                params:this.ajaxParam
            });
            if(code === 200){
                this.$message({
                    message: '修改成功',
                    type: 'success',
                    center: true
                });
                this.$emit("close");
            }
        },

        /** 确定后回传值 */
        crontabFill(value) {
            this.ajaxParam.cronDesc = value
        },
    }
}
</script>

<style scoped lang="scss">
.allForm{
    .el-input{

    }
}
.el-form{
  padding-right: 25px;
}
.el-button-cron{
  transform: translateX(5px);
}
</style>