var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-container"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-card',[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":6}},[_c('span',{staticClass:"text-primary text-pad-right"},[_vm._v("任务名称：")]),_c('el-input',{staticClass:"input searchInput",staticStyle:{"width":"250px"},attrs:{"size":"mini","suffix-icon":"el-icon-search","placeholder":"请输入任务名称"},on:{"input":_vm.queryList},model:{value:(_vm.ajaxParam.jobName),callback:function ($$v) {_vm.$set(_vm.ajaxParam, "jobName", $$v)},expression:"ajaxParam.jobName"}})],1),_c('el-col',{attrs:{"span":6}},[_c('div',{staticClass:"pl-1"},[_c('span',{staticClass:"text-primary text-pad-right"},[_vm._v("任务状态：")]),_c('jobStatusRadioGroup',{on:{"change":function () {
                                    _vm.ajaxParam.currentPage = 1;
                                    _vm.queryList();
                                }},model:{value:(_vm.ajaxParam.jobStatus),callback:function ($$v) {_vm.$set(_vm.ajaxParam, "jobStatus", $$v)},expression:"ajaxParam.jobStatus"}})],1)])],1)],1),_c('el-card',{staticStyle:{"margin-top":"20px"}},[_c('el-row',[_c('vxe-toolbar',{scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('el-row',{attrs:{"type":"flex","justify":"between"}},[_c('el-col',[(
                                        _vm.$hasBtn(
                                            'sys:permissions:config:timed_task:add'
                                        )
                                    )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){_vm.recordRow = null;
                                        _vm.showAddOrEdit = true;}}},[_vm._v("添加")]):_vm._e(),(
                                        _vm.$hasBtn(
                                            'sys:permissions:config:timed_task:batch:delete'
                                        )
                                    )?_c('el-button',{attrs:{"type":"danger","size":"mini","disabled":_vm.batchRecordRows.length === 0
                                            ? true
                                            : false},on:{"click":_vm.batchDel}},[_vm._v("批量删除")]):_vm._e()],1),_c('el-col',{attrs:{"span":1}},[_c('vxe-button',{on:{"click":function () {
                                            _vm.ajaxParam.currentPage = 1;
                                            _vm.ajaxParam.pageSize = 20;
                                            _vm.ajaxParam.jobName = null;
                                            _vm.ajaxParam.jobStatus = null;
                                            _vm.queryList();
                                        }}},[_vm._v("刷新")])],1)],1)]},proxy:true}])}),_c('vxe-table',{ref:"table",staticClass:"vxe-table-element",attrs:{"border":"","resizable":"","show-overflow":"","auto-resize":true,"header-row-style":_vm.headerStyle,"row-style":_vm.rowStyleFn,"align":"center","height":"600","data":_vm.records},on:{"checkbox-change":_vm.checkboxChange,"checkbox-all":_vm.checkboxChange}},[_c('vxe-table-column',{attrs:{"type":"checkbox","width":"60","fixed":"left"}}),_c('vxe-table-column',{attrs:{"type":"seq","title":"任务序号","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
                                        var rowIndex = ref.rowIndex;
return [_vm._v(" "+_vm._s(rowIndex + 1)+" ")]}}])}),_c('vxe-table-column',{attrs:{"type":"seq","title":"调度名","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
                                        var rowIndex = ref.rowIndex;
return [_vm._v(" "+_vm._s(row.schedulerName)+" ")]}}])}),_c('vxe-table-column',{attrs:{"type":"seq","title":"任务名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
                                        var rowIndex = ref.rowIndex;
return [_vm._v(" "+_vm._s(row.jobName)+" ")]}}])}),_c('vxe-table-column',{attrs:{"type":"seq","title":"code码"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
                                        var rowIndex = ref.rowIndex;
return [_vm._v(" "+_vm._s(row.triggerCode)+" ")]}}])}),_c('vxe-table-column',{attrs:{"type":"seq","title":"任务组名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
                                        var rowIndex = ref.rowIndex;
return [_vm._v(" "+_vm._s(row.groupCode)+" ")]}}])}),_c('vxe-table-column',{attrs:{"type":"seq","title":"类名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
                                        var rowIndex = ref.rowIndex;
return [_vm._v(" "+_vm._s(row.executeC)+" ")]}}])}),_c('vxe-table-column',{attrs:{"type":"seq","title":"调用方法"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
                                        var rowIndex = ref.rowIndex;
return [_vm._v(" "+_vm._s(row.executeMethod)+" ")]}}])}),_c('vxe-table-column',{attrs:{"type":"seq","title":"cron执行表达式"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
                                        var rowIndex = ref.rowIndex;
return [_vm._v(" "+_vm._s(row.cronDesc)+" ")]}}])}),_c('vxe-table-column',{attrs:{"type":"seq","title":"任务状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
                                        var rowIndex = ref.rowIndex;
return [_vm._v(" "+_vm._s(row.jobStatusDesc)+" ")]}}])}),_c('vxe-table-column',{attrs:{"type":"seq","title":"启动/停用","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
                                        var rowIndex = ref.rowIndex;
return [_c('el-switch',{on:{"change":function($event){return _vm.changeSwitch(row)}},model:{value:(row.switch),callback:function ($$v) {_vm.$set(row, "switch", $$v)},expression:"row.switch"}})]}}])}),_c('vxe-table-column',{attrs:{"field":"","title":"操作","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
                                        var rowIndex = ref.rowIndex;
return [_c('el-dropdown',{attrs:{"size":"small","type":"primary"}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v(" 操作"),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(
                                            _vm.$hasBtn(
                                                'sys:permissions:config:timed_task:edit'
                                            )
                                        )?_c('el-dropdown-item',{attrs:{"icon":"el-icon-edit"},nativeOn:{"click":function($event){_vm.recordRow = row;
                                            _vm.showAddOrEdit = true;}}},[_vm._v("修改 ")]):_vm._e(),(
                                            _vm.$hasBtn(
                                                'sys:permissions:config:timed_task:delete'
                                            )
                                        )?_c('el-dropdown-item',{attrs:{"icon":"el-icon-delete"},nativeOn:{"click":function($event){return _vm.del(row)}}},[_vm._v("删除 ")]):_vm._e(),(
                                            _vm.$hasBtn(
                                                'sys:permissions:config:timed_task:execute'
                                            )
                                        )?_c('el-dropdown-item',{attrs:{"icon":"el-icon-delete"},nativeOn:{"click":function($event){return _vm.execute(row)}}},[_vm._v("立即执行 ")]):_vm._e(),(
                                            _vm.$hasBtn(
                                                'sys:permissions:config:timed_task:view'
                                            )
                                        )?_c('el-dropdown-item',{attrs:{"icon":"el-icon-delete"},nativeOn:{"click":function($event){_vm.recordRow = row;
                                            _vm.showLookDetails = true;}}},[_vm._v("任务详细 ")]):_vm._e()],1)],1)]}}])})],1),_c('div',{staticStyle:{"height":"20px"}}),_c('el-pagination',{attrs:{"background":"","current-page":_vm.ajaxParam.currentPage,"page-sizes":[5, 10, 15, 20, 50, 100],"page-size":_vm.ajaxParam.pageSize,"layout":"total, ->, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.queryList,"current-change":_vm.queryList}})],1)],1)],1),(_vm.showAddOrEdit)?_c('AddOrEdit',{attrs:{"visible":_vm.showAddOrEdit,"recordRow":_vm.recordRow},on:{"close":function () {
                _vm.showAddOrEdit = false;
                _vm.ajaxParam.currentPage = 1;
                _vm.queryList();
            },"update:visible":function($event){_vm.showAddOrEdit=$event}}}):_vm._e(),(_vm.showLookDetails)?_c('LookDetails',{attrs:{"visible":_vm.showLookDetails,"recordRow":_vm.recordRow},on:{"update:visible":function($event){_vm.showLookDetails=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }