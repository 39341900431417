<template>
    <div class="dashboard-container">
        <el-row :gutter="20">
            <el-card>
                <el-row type="flex">
                    <el-col :span="6">
                        <span class="text-primary text-pad-right"
                            >任务名称：</span
                        >
                        <el-input
                            v-model="ajaxParam.jobName"
                            size="mini"
                            class="input searchInput"
                            suffix-icon="el-icon-search"
                            placeholder="请输入任务名称"
                            @input="queryList"
                            style="width: 250px"
                        />
                    </el-col>
                    <el-col :span="6">
                        <div class="pl-1">
                            <span class="text-primary text-pad-right"
                                >任务状态：</span
                            >
                            <jobStatusRadioGroup
                                v-model="ajaxParam.jobStatus"
                                @change="
                                    () => {
                                        ajaxParam.currentPage = 1;
                                        queryList();
                                    }
                                "
                            />
                        </div>
                    </el-col>
                </el-row>
            </el-card>

            <el-card style="margin-top: 20px">
                <el-row>
                    <vxe-toolbar>
                        <template v-slot:buttons>
                            <el-row type="flex" justify="between">
                                <el-col>
                                    <el-button
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:config:timed_task:add'
                                            )
                                        "
                                        type="primary"
                                        size="mini"
                                        @click="
                                            recordRow = null;
                                            showAddOrEdit = true;
                                        "
                                        >添加</el-button
                                    >
                                    <el-button
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:config:timed_task:batch:delete'
                                            )
                                        "
                                        type="danger"
                                        size="mini"
                                        @click="batchDel"
                                        :disabled="
                                            batchRecordRows.length === 0
                                                ? true
                                                : false
                                        "
                                        >批量删除</el-button
                                    >
                                </el-col>
                                <el-col :span="1">
                                    <vxe-button
                                        @click="
                                            () => {
                                                ajaxParam.currentPage = 1;
                                                ajaxParam.pageSize = 20;
                                                ajaxParam.jobName = null;
                                                ajaxParam.jobStatus = null;
                                                queryList();
                                            }
                                        "
                                        >刷新</vxe-button
                                    >
                                </el-col>
                            </el-row>
                        </template>
                    </vxe-toolbar>

                    <vxe-table
                        ref="table"
                        border
                        resizable
                        show-overflow
                        :auto-resize="true"
                        :header-row-style="headerStyle"
                        :row-style="rowStyleFn"
                        align="center"
                        class="vxe-table-element"
                        height="600"
                        :data="records"
                        @checkbox-change="checkboxChange"
                        @checkbox-all="checkboxChange"
                    >
                        <vxe-table-column
                            type="checkbox"
                            width="60"
                            fixed="left"
                        />

                        <vxe-table-column
                            type="seq"
                            title="任务序号"
                            width="120"
                        >
                            <template v-slot="{ row, rowIndex }">
                                {{ rowIndex + 1 }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column type="seq" title="调度名" width="120">
                            <template v-slot="{ row, rowIndex }">
                                {{ row.schedulerName }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column type="seq" title="任务名称">
                            <template v-slot="{ row, rowIndex }">
                                {{ row.jobName }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column type="seq" title="code码">
                            <template v-slot="{ row, rowIndex }">
                                {{ row.triggerCode }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column type="seq" title="任务组名">
                            <template v-slot="{ row, rowIndex }">
                                {{ row.groupCode }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column type="seq" title="类名">
                            <template v-slot="{ row, rowIndex }">
                                {{ row.executeC }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column type="seq" title="调用方法">
                            <template v-slot="{ row, rowIndex }">
                                {{ row.executeMethod }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column type="seq" title="cron执行表达式">
                            <template v-slot="{ row, rowIndex }">
                                {{ row.cronDesc }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column type="seq" title="任务状态">
                            <template v-slot="{ row, rowIndex }">
                                {{ row.jobStatusDesc }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column
                            type="seq"
                            title="启动/停用"
                            width="120"
                        >
                            <template v-slot="{ row, rowIndex }">
                                <!--jobStatus: "PAUSED" 暂停-->
                                <!--                                {{ row.jobStatusDesc }}-->
                                <el-switch
                                    v-model="row.switch"
                                    @change="changeSwitch(row)"
                                ></el-switch>
                            </template>
                        </vxe-table-column>

                        <vxe-table-column field="" title="操作" width="120">
                            <template v-slot="{ row, rowIndex }">
                                <el-dropdown size="small" type="primary">
                                    <el-button size="small" type="primary">
                                        操作<i
                                            class="el-icon-arrow-down el-icon--right"
                                        />
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item
                                            v-if="
                                                $hasBtn(
                                                    'sys:permissions:config:timed_task:edit'
                                                )
                                            "
                                            icon="el-icon-edit"
                                            @click.native="
                                                recordRow = row;
                                                showAddOrEdit = true;
                                            "
                                            >修改
                                        </el-dropdown-item>
                                        <el-dropdown-item
                                            v-if="
                                                $hasBtn(
                                                    'sys:permissions:config:timed_task:delete'
                                                )
                                            "
                                            icon="el-icon-delete"
                                            @click.native="del(row)"
                                            >删除
                                        </el-dropdown-item>
                                        <el-dropdown-item
                                            v-if="
                                                $hasBtn(
                                                    'sys:permissions:config:timed_task:execute'
                                                )
                                            "
                                            icon="el-icon-delete"
                                            @click.native="execute(row)"
                                            >立即执行
                                        </el-dropdown-item>
                                        <el-dropdown-item
                                            v-if="
                                                $hasBtn(
                                                    'sys:permissions:config:timed_task:view'
                                                )
                                            "
                                            icon="el-icon-delete"
                                            @click.native="
                                                recordRow = row;
                                                showLookDetails = true;
                                            "
                                            >任务详细
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </vxe-table-column>
                    </vxe-table>

                    <div style="height: 20px" />
                    <el-pagination
                        background
                        :current-page="ajaxParam.currentPage"
                        :page-sizes="[5, 10, 15, 20, 50, 100]"
                        :page-size="ajaxParam.pageSize"
                        layout="total, ->, sizes, prev, pager, next, jumper"
                        :total="total"
                        @size-change="queryList"
                        @current-change="queryList"
                    />
                </el-row>
            </el-card>
        </el-row>

        <AddOrEdit
            @close="
                () => {
                    showAddOrEdit = false;
                    ajaxParam.currentPage = 1;
                    queryList();
                }
            "
            v-if="showAddOrEdit"
            :visible.sync="showAddOrEdit"
            :recordRow="recordRow"
        />
        <LookDetails
            :visible.sync="showLookDetails"
            v-if="showLookDetails"
            :recordRow="recordRow"
        />
    </div>
</template>

<script>
import AddOrEdit from "./components/AddOrEdit.vue";
import LookDetails from "./components/LookDetails.vue";
import jobStatusRadioGroup from "./components/jobStatusRadioGroup.vue";
export default {
    name: "timed_task",
    components: { AddOrEdit, LookDetails, jobStatusRadioGroup },
    data() {
        return {
            ajaxParam: {
                currentPage: 1,
                pageSize: 20,
                jobName: null, // 任务名称
                jobStatus: null, // 任务状态  jobStatusRadioGroup
                // direction:"ASC" //排序方式，默认降序 DESC, ASC
                // property:""   // 排序字段，默认创建时间
            },

            records: [],
            total: 0,

            recordRow: null, //要操作的列表选中的一项  当前要操作的行
            batchRecordRows: [], //批量操作的数据

            showAddOrEdit: false,
            showLookDetails: false,
        };
    },
    created() {
        this.queryList();
    },
    methods: {
        async queryList() {
            let { code, data } = await this.$api.get(
                "/quartz/server/escort/web/quartzManage",
                {
                    params: this.ajaxParam,
                }
            );
            if (code === 200) {
                data.content.forEach((item) => {
                    item.switch = item.jobStatus === "PAUSED" ? false : true;
                });
                this.records = data.content;
                this.total = ~~data.totalElements;
            }
        },

        checkboxChange(row) {
            //多选操作
            this.batchRecordRows = row.records;
        },

        async changeSwitch(row) {
            let { code } = await this.$api.post(
                !row.switch
                    ? `/quartz/server/escort/web/quartzManage/pause`
                    : `/quartz/server/escort/web/quartzManage/resume`,
                null,
                {
                    params: {
                        groupCode: row.groupCode,
                        jobCode: row.jobCode,
                    },
                }
            );
            if (code === 200) {
                this.$message({
                    message: `${row.switch ? "启动" : "停用"}成功`,
                    type: "success",
                    center: true,
                });
                this.ajaxParam.currentPage = 1;
                this.queryList();
            }
        },

        execute(row) {
            this.$confirm(
                "确认要立即执行一次“系统默认（无参）”任务吗？?",
                "系统提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(async () => {
                    let { code } = await this.$api.post(
                        `/quartz/server/escort/web/quartzManage/execute`,
                        null,
                        {
                            params: {
                                groupCode: row.groupCode,
                                jobCode: row.jobCode,
                            },
                        }
                    );
                    if (code === 200) {
                        this.$message({
                            message: "执行操作成功",
                            type: "success",
                            center: true,
                        });
                        // this.ajaxParam.currentPage = 1;
                        // this.queryList();
                    }
                })
                .catch(() => {});
        },
        del(row) {
            this.$confirm("确认删除吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                    let { code } = await this.$api.delete(
                        `/quartz/server/escort/web/quartzManage/jobCode/${row.jobCode}/jobGroup/${row.groupCode}`
                    );
                    if (code === 200) {
                        this.$message({
                            message: "删除成功",
                            type: "success",
                            center: true,
                        });
                        this.ajaxParam.currentPage = 1;
                        this.queryList();
                    }
                })
                .catch(() => {});
        },
        batchDel() {
            //    批量删除
            this.$confirm("确认删除吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                    let ids = this.batchRecordRows.map((item) => {
                        return {
                            groupCode: item.groupCode,
                            jobCode: item.jobCode,
                        };
                    });
                    let { code } = await this.$api.delete(
                        "/quartz/server/escort/web/quartzManage/batchDelete",
                        {
                            data: ids,
                        }
                    );
                    if (code === 200) {
                        this.$message({
                            message: "批量删除成功",
                            type: "success",
                            center: true,
                        });
                        this.ajaxParam.currentPage = 1;
                        this.batchRecordRows = [];
                        this.queryList();
                    }
                })
                .catch(() => {});
        },
    },
};
</script>

<style scoped>
.dashboard-container {
    box-sizing: border-box;
    padding: 0 10px;
}
</style>
