function key1Fn(rule, value, callback) {
    if (value.length > 20) {
        callback(new Error("字数不能多余于20个"));
    } else {
        callback();
    }
}

export default {
    key: [
        { trigger: "change", required: true, message: "请录入数据" },
        { validator: key1Fn, trigger: "change" },
    ],
    expression:[
        { trigger: "change", required: true, message: "请录入数据" },
    ],
    cronDesc:[
        { trigger: "change", required: true, message: "请录入数据" },
    ],
    executeC:[
        { trigger: "change", required: true, message: "请录入数据" },
    ],
    executeCode:[
        { trigger: "change", required: true, message: "请录入数据" },
    ],
    executeMethod:[
        { trigger: "change", required: true, message: "请录入数据" },
    ],
    groupCode:[
        { trigger: "change", required: true, message: "请录入数据" },
        { validator: key1Fn, trigger: "change" },
    ],

    jobCode: [
        { trigger: "change", required: true, message: "请录入数据" },
        { validator: key1Fn, trigger: "change" },
    ],
    jobName: [
        { trigger: "change", required: true, message: "请录入数据" },
        { validator: key1Fn, trigger: "change" },
    ],
    // key3: [{ validator: validatePass, trigger: "change" }],
    // key4: [{ validator: validatePass, trigger: "change" }],
};
