<template>
    <el-select size="small"
               v-bind="$attrs"
               v-on="$listeners"
               placeholder="请选择状态" clearable
              >
        <el-option
                v-for="item in options"
                :key="item.dictCode"
                :label="item.dictName"
                :value="item.dictCode">
        </el-option>
    </el-select>
</template>

<script>
export default {
    name: "jobStatusRadioGroup",
    created() {
        this.queryList();
    },
    data(){
        return{
            options:[]
        }
    },
    methods:{
        async queryList(){
            let {code, data} = await this.$api.get(`/data/server/escort/web/dict/parentCode/JOB_STATUS`);
            if(code === 200){
                this.options = data;
            }
        }
    }
}
</script>

<style scoped>

</style>